<template>
  <div
    v-if="data && data.length > 0"
    class="transaction-history"
  >
    <b-table
      :data="data"
      :total="data.length"
      :per-page="perPage"
    >
      <b-table-column
        v-slot="props"
        field="name"
        label="Agency Name"
      >
        {{ props.row.agency.name }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="started_at"
        label="Started at"
        sortable
      >
        {{ props.row.started_at ? getLocalTime(new Date(props.row.started_at).toLocaleString()) : '-' }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="ended_at"
        label="Ended at"
      >
        {{ props.row.ended_at ? getLocalTime(new Date(props.row.ended_at).toLocaleString()) : '-' }}
      </b-table-column>
      <!-- <b-table-column
        label="Detail"
      >
        <b-button
          icon-right="info-circle"
        />
      </b-table-column> -->
    </b-table>
  </div>
</template>
<script>
import { format } from 'date-fns'

export default {
  name: 'AgencyHistory',
  props: {
    data: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      perPage: 10
    }
  },
  methods: {
    getLocalTime (isotime) {
      return format(new Date(isotime), 'dd/MM/yyyy HH:mm:ss')
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
